@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary-black: #1B1B1B;
}

* {
    color: var(--primary-black);
    font-size: 18px;
    font-family: 'MyanmarSansPro';
    box-sizing: border-box;
}

html, body 
{
    position: relative;
    padding: 0;
    margin: 0;
}


.page 
{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: white;
}

label
{
    cursor: pointer;
}

.input-required::after {
    content: '*';
    color: red;

}

.x-input 
{
    width: 100%;
    height: 50px;
    padding: 13px 22px;
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid rgba(27, 27, 27, 0.4);
    border-radius: 5px;
}

.x-label {
    margin-bottom: 15px;
    font-size: 18px;
    color: var(--primary-black);
    display: block;
}

.x-input:focus {
    outline: none;
    border: 1px solid var(--primary-black);
}

a {
    text-decoration: none;
}

.x-checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
}


input.none-appearance::-webkit-outer-spin-button,
input.none-appearance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].none-appearance {
  -moz-appearance: textfield;
}

