

.search-container 
{
    /* width: 100%; */
    padding: 95px 20px;
    margin: 48px 26px 0 26px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.search-container a {
    margin: 0;
}

.search-container>* 
{
    height: 60px;
}

.search-container input
{   
    height: 60px;
}
.search-container .add-customer {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7.5px;
    border: 1.5px solid rgba(0, 0, 0, 0.55);
}

.search-container .add-customer img {
    width: 30px;
    height: 30px;

}

.table-btn-style-1 {
    width: 115px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #F5F5F5;
    border: 1px solid rgba(27, 27, 27, 0.1);
    border-radius: 5px;

}

.form-info {
    display: flex;
    gap: 45px;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 24px;
    padding-bottom: 20px;
}

.form-info .vdivider 
{
    width: 2px;
    flex-shrink: 0;
    max-height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.form-info .datetime-render 
{
    padding-top: 37px;
    margin-left: 50px;
    max-width: 222px;
}

.form-info .grid-render {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    column-gap: 20px;
    padding: 37px 0px 20px 0px;
}

.form-divider 
{
    width: 99%;
    height: 2px;
    margin-top: 55px;
    margin-right: 18px;
    background-color: rgba(0, 0, 0, 0.1);
}

.sale-complete-popup {
    border-radius: 10px;
    background-color: white;
    max-width: 1555px;
    width: 100%;
    padding: 48px 61px 42px 57px;
}
