
.page-layout 
{
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding: 48px 32px 30px 32px;
    display: flex;
    flex-direction: column;
}

.page-header
{
    width: 100%;
    display: flex;
    align-items: center;
}

.page-header h1 
{
    margin: 0;
    flex-grow: 1;
    font-size: 30px;
}

.search-bar {
    width: 100%;
    max-width: 380px;
    position: relative;
}

.search-bar img {
    width: 22px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 19px;
    transform: translateY(-50%);
}

.search-bar input 
{
    padding-left: 56px;
    width: 100%;
}

.search-bar input:focus {
    outline: none;
}

.create-button 
{
    text-align: center;
    padding: 13px 24px;
    width: 170px;
    height: 50px;
    margin-left: 30px;
    color: #FAFAFA;
    font-size: 18px;
    cursor: pointer;
    background: #1B1B1B;
    border-radius: 5px;
}

.table-container {
    margin-top: 40px;
    overflow: auto;
    flex-grow: 1;
}

.x-table 
{
    width: 100%;
    font-size: 18px;
}

.x-table tr 
{
    padding: 17px 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 16px;
    
}

.x-table td.button-col, .x-table th.button-col {
    max-width: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.x-table td:not(.button-col), .x-table th:not(.button-col) {
    max-width: 250px;
    width:  100%;
}

.x-table td:not(.button-col):first-of-type, .x-table th:not(.button-col):first-of-type
{
    max-width: 50px;
}

.x-table thead tr {
    border-width: 2px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
}

.x-table tbody tr {
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    padding: 32px 49px;
    border-color: rgba(0, 0, 0, 0.2);
}

.x-table tbody tr.hover-effect:hover {
    background-color: #F5F5F5;
}

.x-table .button-col>img {
    width: 36px;
    height: 36px;
    padding: 9px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.55);
}

.x-table .popup-options {
    display: none;
    position: absolute;
    background-color: white;
    /* max-width: 250px; */
    min-width: 250px;
    top: 46px;
    right: -6px;
    z-index: 10;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 8px 10px;
    flex-direction: column;
    gap: 8px;
}

.x-table .popup-options.open {
    display: flex;
}

.x-table .popup-options a {
    display: flex;
    padding: 16px 25px;
    width: 100%;
    gap: 12px;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
}

.x-table .details {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.55);
}

.x-table .popup-options a:hover {
    background-color: #F5F5F5;
}

.x-table .details img {
    width: 18px;
    height: 18px;
}

.x-table .popup-options img{
    width: 18px;
    height: 18px;
}


.pagination-container 
{
    display: flex;
    justify-content: flex-end;
}


ul.pagination {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: 25px;
}

.pagination li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 13px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination li.active {
    color: white;
    background-color: var(--primary-black);
}

.pagination img 
{
    width: 18px;
    height: 18px;
}

.x-input.small-padding {
    padding: 8px 18px;
}

.sub-page-layout-1 
{
    position: relative;
    padding: 117px 32px 30px 64px;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.back-to-page {
    position: absolute;
    left: 43px;
    top: 64px;
    display: flex;
    align-items: center;
    opacity: .5;
    gap: 20px;
}

.back-to-page img {
    width: 18px;
    height: auto;
}

.sub-page-layout-1 .container {
    height: 100%;
    max-width: 100%;
}


.sub-page-layout-1 .form {
    margin-top: 25px;
    flex-grow: 1;
    padding-bottom: 50px;
}

.sub-page-title {
    font-size: 26px;
}


.form-container {
    display: flex;
    height: 100%;
}

.form 
{
    padding-left: 10px;
    padding-right: 50px;
}

.side-card-container {
    flex-shrink: 0;
    padding: 25px 0px 25px 31px;
    border-left: 2px solid rgba(27, 27, 27, 0.1);

}

.side-card {
    padding: 40px 35px 10px 39px;
    background: #F5F5F566;
    max-width: 380px;
    width: 100%;
}

.side-card-list
{
    margin-bottom: 31px;
    display: flex;
    gap: 30px;
}


.white-btn, .black-btn
{
    width: 155px;
    padding: 13px 0;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.white-btn 
{
    background: #F5F5F5;
    color: #1F1F1F66;
    text-align: center;
}


.black-btn
{
    text-align: center;
    background-color: var(--primary-black);
    color: #FAFAFA;

}

.popup-container {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #1b1b1b7d;
    justify-content: center;
    align-items: center;
}

.popup-container.show {
    display: flex;
}

.popup 
{
    width: 560px;
    padding: 40px 115px;
    background-color: white;
    background: #FDFDFD;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

}


.popup h4 {
    font-size: 26px;
}


.popup p {
    text-align: center;
    font-size: 20px;
    color: #575F6E;
    margin-top: 20px;
    margin-bottom: 30px;
}


.icon-button-style-1 {
    box-sizing: content-box;
    width: 18px;
    height: 18px;
    padding: 9px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.55);
}

.item-container {
    margin-top: 50px;
}

.item-container h4 {
    font-size: 22px;
}

.dropdown 
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    z-index: 10;
    background: transparent;
}

.dd-container{
    position: relative;

}

.dd-container::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url('../img/icon-dropdown-arrow.png');
    background-size: contain;
}

.sub-page-layout-1 .sub-title 
{
    font-size: 22px;
}

.sub-page-layout-1 .form-divider
{
    width: 100%;
    height: 2px;
    background: #0000001A;
    margin: 45px 0 50px 0;
}


.password-input
{
    position: relative;
}

.x-divider {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}

.password-input .toggle-icon {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 26px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url('/src/img/icon-hide.png');
    background-size: contain;
    background-position: center;
}

.password-input .toggle-icon.show {
    background-image: url('/src/img/icon-show.png');
}

.home-layout
{
    width: 100%;
    height: 100%;
    padding: 54px 50px 77px 50px;

}

.home-layout .title {
    font-size: 30px;
}

.amount-stats-container {
    margin-top: 29px;
    display: flex;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;
}

.amount-stats-container .box {
    display: block;
    padding: 33px 40px 40px 40px;
    flex-grow: 1;
}

.box:not(:last-of-type)
{
    border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.box .icon {
    width: 50px;
    height: auto;
    margin-bottom: 30px;
}

.box .label {
    margin-bottom: 22px;
    font-size: 20px;
}

.box .amount 
{
    margin: 0;
    padding: 0;
}

.table-title 
{
    font-size: 22px;
}

.header-img-button {
    width: 100%;
    max-width: 50px;
    height: 50px;
    background-color: var(--primary-black);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.header-img-button img {
    width: 24px;
    height: 24px;


}


.customer-month-report th, .customer-month-report td {
    min-width: 80px;
}

.customer-month-report th:nth-child(2), .customer-month-report td:nth-child(2) {
    min-width: 140px;
}



.distribution-report th, .distribution-report td {
    min-width: 160px;
}


.distribution-report th:nth-child(1), .distribution-report td:nth-child(1) {
    min-width: 50px;
}
