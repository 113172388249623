* {
    box-sizing: border-box;
}
#voucher {
    display: none;
    /* max-width: 420px; */
    width: 100%;
    box-shadow: 3px 3px 4px black;
}

.vpage-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    gap: 30px
}

.voucher-container 
{
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
    padding: 9px 12px 50px 13px;
}

.owner {
    font-size: 12px;
    text-align: center;
}

.logo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    align-items: end;
}

.logo-1, .logo-2 {
    width: auto;
    height: 42px;
}

.logo-3 {
    width: auto;
    height: 30px;
    margin-bottom: 3px;
}

.location {
    text-align: center;
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 8px;
}

.contact 
{
    color: white;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #424243;
    padding: 7px 9px 6px 9px;
}

.contact span {
    color: white;
    display: block;
    line-height: 11.9px;
    font-size: 9px;
    letter-spacing: .5px;
}

.contact img {
}

.contact .phone {
    width: 11px;
    height: auto;
    margin-right: 8px;
}

.contact .fb {
    width: 13px;
    height: 13px;
    margin-left: 21px;
    margin-right: 8px;
}

.name-date 
{
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.name-date span {
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    line-height: 24px;
}

.name-date .label {
    display: flex;
    gap: 8px
}

.voucher-table {
    width: 100%;
    border-spacing: 0;
}

.voucher-table th {
    background-color: #424243;
    color: white;
}

.voucher-table{

    border-top: 1px solid rgba(0, 0, 0, 0.55);
    border-left: 1px solid rgba(0, 0, 0, 0.55);
}

.voucher-table td{
    border-right: 1px solid rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid rgba(0, 0, 0, 0.55);
}

.voucher-table th {
    font-weight: normal;
    line-height: 16px !important;
}

.voucher-table th:nth-child(1) {
    width: 40px;
}
.voucher-table th:nth-child(2) {
    width: 50%;
}

.voucher-table th, .voucher-table td {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 7px;
    height: 26px;
}

.voucher-table td:nth-child(1) {
    text-align: center;
}


.voucher-table td:nth-child(3) {
    text-align: center;
}

.voucher-table td:nth-child(4) {
    text-align: end;
}

.voucher-table td:nth-child(5) {
    text-align: end;
}

.footer
{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.note {
    font-size: 12px;
    white-space: nowrap;
}

.total-calc {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.total-calc .item {
    display: flex;
    font-size: 12px;
    gap: 12px;
}

.total-calc .item span {
    font-size: 12px;
    display: block;
}

.total-calc .item span:nth-child(1) {
    min-width: 50px;
}
.total-calc .item span:nth-child(2) {

}
.total-calc .item span:nth-child(3) {
    min-width: 60px;
    text-align: end;
    margin-right: 9px;
}

.sign {
    margin-top: 30px;
    margin-left: 80px;
}
.sign span {
    font-size: 12px;
}

#voucher-page {
    position: absolute;
    left: 18px;
    bottom: 10px;
    font-size: 12px;
}
