.loading-container {
    position: relative;
    max-width: 160px;
    max-height: 160px;
    width: 100%;
    height: 100%;
}


.loading-ball-1, .loading-ball-2 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: rgba(98, 95, 95, 0.704);
    width: 100%;
    height: 100%;
    animation-name: loading-ball;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.loading-ball-2 {
    animation-delay: 1s;
    background-color: rgba(98, 98, 98, 0.855);
}


@keyframes loading-ball {
    0% {
        width: 0%;
        height: 0%;
    }
    50% {
        width: 100%;
        height: 100%;
    }
    100% {
        width: 0%;
        height: 0%;
    }
  }

